<template>
  <div class="flex gap-4 justify-center text-sm sm:text-base">
    <combobox v-model="selected" class="w-full" @update:model-value="addTrip">
      <div class="relative">
        <div class="relative w-full overflow-hidden rounded-lg bg-white border focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-sky-300">
          <combobox-button class="w-full">
            <combobox-input
              class="w-full border-none py-2 pl-3 pr-10 leading-5 text-gray-900 focus:ring-0"
              placeholder="Add trip to your list"
              :display-value="trip => trip?.name"
              @change="query = $event.target.value"
            />
          </combobox-button>
          <combobox-button class="absolute inset-y-0 right-0 flex items-center pr-2">
            <chevron-up-down-icon
              class="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </combobox-button>
        </div>

        <transition-root
          leave="transition ease-in duration-100"
          leave-from="opacity-100"
          leave-to="opacity-0"
          @after-leave="query = ''"
        >
          <combobox-options class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
            <div
              v-if="trips.length === 0"
              class="relative select-none py-2 px-4 text-gray-700"
            >
              Nothing found
            </div>

            <combobox-option
              v-for="trip in trips"
              :key="trip.id"
              class="
                relative flex gap-2 select-none p-2 cursor-pointer
                text-gray-900 ui-active:bg-sky-600 ui-active:text-white
              "
              :value="trip"
            >
              <img :src="trip.logo" class="w-4 h-4 object-cover rounded-full" alt="">

              <div>
                <span
                  class="block truncate"
                  :class="{ 'font-medium': selected, 'font-normal': !selected }"
                >
                  {{ trip.name }}
                </span>

                <span class="text-sm opacity-50">
                  {{ trip.date }}
                </span>
              </div>
            </combobox-option>
          </combobox-options>
        </transition-root>
      </div>
    </combobox>
  </div>
</template>

<script setup>
import Fuse from "fuse.js"
import { ChevronUpDownIcon } from "@heroicons/vue/20/solid"
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  TransitionRoot,
} from "@headlessui/vue"
import { computed, ref } from "vue"

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
})

const fuse = new Fuse([], {
  findAllMatches: true,
  keys: ["name", "date"],
  shouldSort: false,
  useExtendedSearch: true,
})
const dataset = ref([])
const selected = ref(null)
const query = ref("")

axios.get("/trips").then((response) => {
  fuse.setCollection(response.data)
  dataset.value = response.data
})

const trips = computed(() => {
  if (!fuse) {
    return []
  }

  if (!query.value.trim(" ").length) {
    return dataset.value.slice(0, 20)
  }

  const term = query.value.trim(" ").split(" ").map(word => `'${word}`).join(" ")

  return fuse.search(term).slice(0, 20).map(result => result.item)
})

const addTrip = () => {
  if (!selected.value) {
    return
  }

  axios.post(`/@${props.user.username}/trips`, { id: selected.value.id }).then(() => {
    toast.success("Saved trip to your list")

    window.location.reload()
  }, () => {
    toast.error("There was a problem adding the trip to your list")
  })
}
</script>
