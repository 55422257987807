<template>
  <button type="button" @click="submit">
    <slot/>
  </button>
</template>

<script setup>
const props = defineProps({
  community: Object,
  trip: Object,
})

const submit = () => {
  Swal.fire({
    title: `<span>Delete trip from <span class="whitespace-nowrap">${props.community.name}</span></span>`,
    html: `Are you sure you want to delete <span class="whitespace-nowrap">${props.trip.name} ${props.trip.short_date}</span>?`,
    icon: "warning",
    confirmButtonColor: "#d33",
    confirmButtonText: "Delete trip",
    showCancelButton: true,
    showCloseButton: true,
  }).then(result => {
    if (!result.value) {
      return
    }

    axios.delete(`/api/communities/${props.community.slug}/trips/${props.trip.id}`).then(() => {
      window.location.reload()
    }).catch(() => {
      Swal.fire({
        title: `There was a problem deleting ${props.trip.name} ${props.trip.short_date}`,
        icon: "error",
      })
    })
  })
}
</script>
