<template>
  <a v-if="href" :href="href" rel="nofollow" @click="bookNow">
    <slot/>
  </a>
  <button v-else type="button" @click="bookNow">
    <slot/>
  </button>
</template>

<script setup>
import useClipboard from "vue-clipboard3"

const props = defineProps({
  href: String,
  coupon: String,
  variant: String,
})

const { toClipboard } = useClipboard()

const bookNow = () => {
  if (props.coupon) {
    copyDiscount(props.coupon)
  }
  trackClick(props.variant)
}

const copyDiscount = (code) => {
  toClipboard(code).then(() => {
    toast.success("Discount code copied to clipboard")
  })
}

const trackClick = (key) => {
  const src = window.location.pathname.slice(1)
  const dst = encodeURIComponent(props.href)

  axios.post(`/${key}-link/${src}/${dst}`).then(() => {
    console.log("Tracking successful")
  }).catch(error => {
    console.error("Tracking failed", error)
  })
}
</script>
