<template>
  <menu-item
    as="button"
    :class="[
      'group flex w-full items-center gap-2 p-2 text-sm text-slate rounded-md',
      'ui-active:bg-sky-600 ui-active:text-white',
    ]"
    @click="!toggle ? addTrip() : removeTrip()"
  >
    <template v-if="!toggle">
      <plus-icon class="w-5 h-5" aria-hidden="true"/>
      Add to your trips
    </template>
    <template v-else>
      <minus-icon class="w-5 h-5" aria-hidden="true"/>
      Remove from your trips
    </template>
  </menu-item>
</template>

<script setup>
import { MenuItem } from "@headlessui/vue"
import { MinusIcon, PlusIcon } from "@heroicons/vue/20/solid"

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  toggle: {
    type: Boolean,
    required: true,
  },
  user: {
    type: Object,
    required: true,
  },
})

const addTrip = () => {
  axios.post(`/@${props.user.username}/trips`, { id: props.id }).then(() => {
    toast.success("Saved trip to your list")

    window.location.reload()
  }, () => {
    toast.error("There was a problem adding the trip to your list")
  })
}

const removeTrip = () => {
  axios.delete(`/@${props.user.username}/trips/${props.id}`).then(() => {
    toast.success("Removed trip from your list")

    window.location.reload()
  }, () => {
    toast.error("There was a problem removing the trip from your list")
  })
}
</script>
