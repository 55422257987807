<template>
  <button
    class="
      inline-flex gap-2 p-2 items-center justify-center text-sm sm:px-3
      sm:text-base
    "
    :class="color"
    :title="title"
    type="button"
    @click="toggleFollow"
    @mouseenter="mouseenter"
    @mouseleave="mouseleave"
  >
    <i :class="['fa', icon]"/>
    <span class="font-weight-bold">{{ label }}</span>
  </button>
</template>

<script setup>
import { computed, ref } from "vue"

const props = defineProps({
  name: String,
  url: {
    type: String,
    required: true,
  },
  value: {
    type: Boolean,
    required: true,
  },
})

const follows = ref(props.value)
const hover = ref(false)
const recent = ref(false)
const submit = ref(false)

const action = computed(() => {
  return follows.value ? "unfollow" : "follow"
})

const icon = computed(() => {
  if (hover.value && follows.value && !recent.value) {
    return "fa-user-times"
  }

  return follows.value ? "fa-user-check" : "fa-plus"
})

const color = computed(() => {
  if (hover.value && follows.value && !recent.value) {
    return "text-red"
  }

  return null
})

const label = computed(() => {
  if (hover.value && follows.value && !recent.value) {
    return "Unfollow"
  }

  return follows.value ? "Following" : "Follow"
})

const title = computed(() => {
  return `${label.value} ${props.name.value}`
})

const toggleFollow = () => {
  if (!coworkations.user.is_authenticated) {
    $("#signup").modal("show")

    return
  }

  if (submit.value) {
    return
  }

  submit.value = true

  axios.post(`${props.url}/${action.value}`).then(() => {
    recent.value = true
    follows.value = !follows.value
  }).finally(() => {
    submit.value = false
  })
}

const mouseenter = () => {
  hover.value = true
}

const mouseleave = () => {
  recent.value = false
  hover.value = false
}
</script>
