const templates = {
  renderAutocomplete: (item, value, counts) => {
    const count = item.count ?? null
    const has_new = item.has_new ?? false
    const is_active = item.is_active ?? true

    if (counts) {
      return `
        <div class="!flex justify-between">
          <div class="truncate">
            ${templates.renderAutocompleteContent(item, value)}
          </div>
          <div class="text-muted text-right whitespace-nowrap">
            ${!is_active ? '<span class="badge badge-secondary small text-muted">Archived</span>' : ""}
            ${is_active && has_new ? '<span class="badge badge-primary small">New trips</span>' : ""}
            ${count ? `<small>${count} trips</small>` : ""}
          </div>
        </div>
      `
    }

    return `
      <div class="truncate">
        ${templates.renderAutocompleteContent(item, value)}
      </div>
    `
  },
  renderAutocompleteContent: (item, value) => {
    return `
      <img src="${item.image}" class="inline" title="${item.name}" role="presentation">
      ${value.split(", ")[0]}
      <small class="text-muted">${value.split(", ")[1] || ""}</small>
    `
  },
  renderMarker: (text, trip) => {
    if (trip.community.logo) {
      const image = `https://coworkations.com/images/resize:fill:140:140:1:1/uploads/${trip.community.logo}`

      return `
        <div class="marker__content">
          <img src="${image}" class="community-logo" width="16" height="16" title="${trip.community.name}" role="presentation">
          ${text}
        </div>
      `
    }

    return `
      <div class="marker__content">
        ${text || "-"}
      </div>
    `
  },
}

window.templates = templates
