<template>
  <button type="button" @click="submit">
    <slot/>
  </button>
</template>

<script setup>
const props = defineProps({
  community: Object,
  user: Object,
})

const submit = () => {
  const email = props.user.email

  Swal.fire({
    title: `<span>Resend invite to <span class="whitespace-nowrap">${props.community.name}</span></span>`,
    html: `Are you sure you want to send another invite to manage <span class="whitespace-nowrap">${props.community.name}</span> to <span class="whitespace-nowrap">${props.user.display_name}</span>?`,
    confirmButtonColor: "#f0ad4e",
    confirmButtonText: "Resend invite",
    showCancelButton: true,
    showCloseButton: true,
  }).then(result => {
    if (!result.value) {
      return
    }

    axios.post(`/api/communities/${props.community.slug}/users`, { email }).then(() => {
      Swal.fire({
        title: `Invite sent to ${email}`,
        text: "Ask them to check their email",
        icon: "success",
      })
    }).catch(() => {
      Swal.fire({
        title: `There was a problem inviting ${email}`,
        icon: "error",
      })
    })
  })
}
</script>
