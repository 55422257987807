<template>
  <button type="button" @click="submit">
    <slot/>
  </button>
</template>

<script setup>
const props = defineProps({
  community: Object,
})

const submit = () => {
  Swal.fire({
    title: `<span>Invite a user to <span class="whitespace-nowrap">${props.community.name}</span></span>`,
    text: "They'll receive a personal invite with a link to sign in",
    input: "email",
    inputLabel: "Email address",
    inputPlaceholder: "Enter email address",
    confirmButtonColor: "#f0ad4e",
    confirmButtonText: "Send invite",
    showCancelButton: true,
    showCloseButton: true,
  }).then(result => {
    const email = result.value

    if (!email) {
      return
    }

    axios.post(`/api/communities/${props.community.slug}/users`, { email }).then(() => {
      Swal.fire({
        title: `Invite sent to ${email}`,
        text: "Ask them to check their email",
        icon: "success",
        didClose: () => window.location.reload(),
      })
    }).catch(() => {
      Swal.fire({
        title: `There was a problem inviting ${email}`,
        icon: "error",
      })
    })
  })
}
</script>
