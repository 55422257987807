<template>
  <form action="" method="post" @submit.prevent="saveCurrency">
    <div class="form-group">
      <label for="id_currency" class="sr-only">Currency</label>

      <select
        id="id_currency"
        v-model="currency"
        name="currency"
        class="form-control"
        required
      >
        <option v-for="[ code, symbol ] in currencies" :key="code" :value="code">
          {{ code }} ({{ symbol }})
        </option>
      </select>
    </div>

    <button class="btn btn-primary" type="submit">
      Set currency
    </button>
  </form>
</template>

<script setup>
import { ref, watch } from "vue"
import { useCookies } from "vue3-cookies"

const user = coworkations.user

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
})

const { cookies } = useCookies()

const currency = ref()

const currencies = [
  ["AED", "د.إ"],
  ["ARS", "$"],
  ["AUD", "$"],
  ["BGN", "лв"],
  ["BRL", "R$"],
  ["CAD", "$"],
  ["CHF", "CHF"],
  ["CLP", "$"],
  ["CNY", "¥"],
  ["COP", "$"],
  ["CRC", "₡"],
  ["CZK", "Kč"],
  ["DKK", "kr"],
  ["EUR", "€"],
  ["GBP", "£"],
  ["HKD", "$"],
  ["HRK", "kn"],
  ["HUF", "Ft"],
  ["IDR", "Rp"],
  ["ILS", "₪"],
  ["JPY", "¥"],
  ["KRW", "₩"],
  ["MAD", "م.د."],
  ["MXN", "$"],
  ["MYR", "RM"],
  ["NOK", "kr"],
  ["NZD", "$"],
  ["PEN", "S/."],
  ["PHP", "₱"],
  ["PLN", "zł"],
  ["RON", "lei"],
  ["RUB", "руб"],
  ["SAR", "﷼"],
  ["SEK", "kr"],
  ["SGD", "$"],
  ["THB", "฿"],
  ["TRY", "TL"],
  ["TWD", "NT$"],
  ["UAH", "₴"],
  ["USD", "$"],
  ["UYU", "$U"],
  ["VND", "₫"],
  ["ZAR", "R"],
]

const submitting = ref(false)

const redirectSearch = () => {
  $(".modal").modal("hide")

  let path = `/search?${$(document.forms.search).serialize()}`

  path = path.replace(/currency=\w*/g, `currency=${currency.value}`)
  path = path.replace(/(&|\?)(min|max)_price=[\w.]*/g, "")

  window.location.href = path
}

const saveCurrency = () => {
  if (submitting.value) {
    return
  }

  spinner.show()

  cookies.set("currency", currency.value)

  if (user.is_authenticated) {
    saveUser()
  } else if (document.forms.search) {
    redirectSearch()
  }
}

const saveUser = () => {
  submitting.value = true

  axios.patch(`/api/users/${user.id}`, { currency: currency.value }).then(() => {
    if (document.forms.search) {
      return redirectSearch()
    }

    spinner.hide()

    Swal.fire({
      title: "Currency updated",
      text: "Your changes have been saved",
      icon: "success",
    })
  }).catch(() => {
    spinner.hide()

    Swal.fire({
      title: "There was a problem updating your currency",
      text: "Your changes have not been saved",
      icon: "error",
    })
  }).finally(() => {
    submitting.value = false
  })
}

watch(
  () => props.value,
  newValue => {
    currency.value = newValue
  },
  { immediate: true },
)
</script>
