<template>
  <a v-bind="{ href, ...$attrs }" @click.prevent="openModal">
    <slot/>
  </a>
  <focus-trap v-if="showModal" v-model:active="showModal">
    <div
      class="bg-zinc-950/75 fixed inset-0 grid wh-screen md:p-4 lg:p-8 xl:p-16 items-center justify-center overflow-auto z-[2000]"
      role="dialog"
      @click="closeModal"
    >
      <button
        class="
          fixed top-0 right-0 px-3 py-4 text-white text-4xl
          hover:text-sky-700
          dark:hover:text-red-400
          focus:ring-0
        "
        aria-label="Close modal"
        type="button"
        @click="closeModal"
      >
        <i class="fa fa-close fa-fw"/>
      </button>

      <img
        :src="href"
        class="shadow-2xl"
        @click.stop
      >
    </div>
  </focus-trap>
</template>

<script setup>
import { FocusTrap } from "focus-trap-vue"
import { ref } from "vue"

defineProps({
  href: String,
})

const showModal = ref(false)

const openModal = () => {
  showModal.value = true
}

const closeModal = () => {
  showModal.value = false
}
</script>
