<template>
  <button
    class="inline-flex gap-2 p-2 items-center justify-center"
    :aria-label="title"
    :class="{ 'text-sm sm:px-3 sm:text-base': !hideLabel }"
    type="button"
    v-bind="{ title }"
    @click="toggleLike"
  >
    <i
      class="fa fa-heart"
      :class="hideLabel ? [
        liked ? 'text-red/90 stroke-white' : 'text-black/50 stroke-white',
      ] : [
        liked ? 'text-red stroke-red' : 'text-transparent stroke-slate',
      ]"
    />
    <span v-if="!hideLabel" class="font-weight-bold">{{ label }}</span>
  </button>
</template>

<script setup>
import colors from "@/colors"
import { computed, ref } from "vue"

const props = defineProps({
  hideLabel: Boolean,
  url: {
    type: String,
    required: true,
  },
  value: {
    type: Boolean,
    required: true,
  },
})

const liked = ref(props.value)
const submit = ref(false)

const action = computed(() => {
  return liked.value ? "unfollow" : "follow"
})

const label = computed(() => {
  return liked.value ? "Saved" : "Save"
})

const title = computed(() => {
  return liked.value ? "Save to your favorites" : "Remove from your favorites"
})

const toggleLike = () => {
  if (!coworkations.user.is_authenticated) {
    $("#signup").modal("show")

    return
  }

  if (submit.value) {
    return
  }

  submit.value = true

  axios.post(`${props.url}/${action.value}`).then(() => {
    liked.value = !liked.value

    if (liked.value) {
      toast.info("Saved to favorites", { iconHtml: "<i class=\"fas fa-heart text-red text-xl\"/>" })
    } else {
      toast.info("Removed from favorites", { iconHtml: "<i class=\"far fa-heart text-xl\"/>" })
    }
  }).finally(() => {
    submit.value = false
  })
}
</script>

<style scoped>
  .stroke-slate {
    text-stroke: v-bind("colors.slate.DEFAULT");
    text-stroke-width: 2px;
    -webkit-text-stroke: v-bind("colors.slate.DEFAULT");
    -webkit-text-stroke-width: 2px;
  }

  .stroke-red {
    text-stroke: v-bind("colors.red.DEFAULT");
    text-stroke-width: 2px;
    -webkit-text-stroke: v-bind("colors.red.DEFAULT");
    -webkit-text-stroke-width: 2px;
  }

  .stroke-white {
    text-stroke: white;
    text-stroke-width: 2px;
    -webkit-text-stroke: white;
    -webkit-text-stroke-width: 2px;
  }
</style>
