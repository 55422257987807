const search = (value) => {
  const form = document.forms.search

  const query = value.trim()

  const filters = $(".filter[data-auto]").map(function () {
    const text = $(this).text().replace(/[^\w\s]/g, "").toLowerCase().trim()

    return { [text]: $(this).find("input:checkbox").first() }
  }).get().reduce((accumulator, currentValue) => {
    return Object.assign(accumulator, currentValue)
  }, {})

  if (query) {
    const filter = filters[query.toLowerCase()]

    $(".filter[data-auto] input:checkbox").not(filter).prop("checked", false)

    if (filter) {
      form.q.value = ""

      filter.prop("checked", true).change()

      return
    }
  }

  $(form.q).val(query).change()
}

const reset = () => {
  const form = document.forms.search

  const inputs = Array.from(form.querySelectorAll("input"))

  for (const input of inputs) {
    switch (input.type.toLowerCase()) {
      case "checkbox":
      case "radio":
        if (input.name == "rate") {
          // do nothing
        } else if (input.name == "room_type" && !input.value.length) {
          input.checked = true
        } else if (input.checked) {
          input.checked = false
        }
        break
      case "range":
        if (input.name.slice(0, 3) === "min") {
          input.value = input.min
        } else if (input.name.slice(0, 3) === "max") {
          input.value = input.max
        }
        break
      default:
        input.value = ""
    }
  }

  resetDate()
  resetDuration()
  resetPrice()
  resetQuery()

  $(form).submit()
}

export const resetDate = () => {
  const form = document.forms.search

  form.from_date.value = ""
  form.to_date.value = ""

  $(".datepicker--from").datepicker("setDate", null)
  $(".datepicker--to").datepicker("setDate", null)

  $(form).change()
}

export const resetDuration = () => {
  window.setDuration()
}

export const resetPrice = () => {
  const form = document.forms.search

  form.min_price.value = form.min_price.min
  form.max_price.value = form.max_price.max

  $(".price-slider .slider")[0].noUiSlider.set([
    form.min_price.min,
    form.max_price.max,
  ])

  $(form).change()
}

export const resetRating = () => {
  const form = document.forms.search

  $(form).find("input:radio[name=min_rating]:checked").prop("checked", false).change()
}

export const resetQuery = () => {
  const form = document.forms.search

  $("#nav_search_q").val("")

  $(".navbar-search .reset-btn").addClass("hidden")

  form.q.value = ""

  $(form).change()
}

window.search = search
window.reset = reset
