import { addMonths } from "date-fns"

const formats = {
  full: { dateStyle: "long", timeStyle: "full" },
  isodate: { year: "numeric", month: "2-digit", day: "2-digit" },
  long: { month: "short", day: "numeric", year: "numeric" },
  month: { month: "short", year: "numeric" },
  short: { month: "short", day: "numeric" },
}

export const formatDate = (date, format) => {
  if (date === null) {
    return ""
  }

  const formatter = new Intl.DateTimeFormat("en-US", formats[format] ?? format)

  if (format === "isodate") {
    const parts = formatter.formatToParts(date)

    return `${parts[4].value}-${parts[0].value}-${parts[2].value}`
  }

  return formatter.format(date)
}

const endOfMonth = function (date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}

const nextMonths = (months) => {
  setDate(today, addMonths(today, months))
}

const setDate = (startDate, endDate = null) => {
  $(".datepicker--from").datepicker("setDate", startOfMonth(new Date(startDate)))
  $(".datepicker--to").datepicker("setDate", endOfMonth(new Date(endDate ?? startDate)))
}

const setDuration = (min, max) => {
  const form = document.forms.search

  form.min_duration.value = min ?? form.min_duration.min
  form.max_duration.value = max ?? form.max_duration.max

  $(".duration-slider .slider")[0].noUiSlider.set([
    form.min_duration.value,
    form.max_duration.value,
  ])

  $(form).change()
}

const setMonth = (date) => {
  setDate(date)
}

const setYear = (year) => {
  setDate(`${year}-01`, `${year}-12`)
}

const startOfMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1)
}

const today = new Date()

window.addMonths = addMonths
window.endOfMonth = endOfMonth
window.formatDate = formatDate
window.nextMonths = nextMonths
window.setDuration = setDuration
window.setMonth = setMonth
window.setYear = setYear
