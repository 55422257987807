const closeFilters = () => {
  $(".search button:contains('Close')").first().click()
}

const toggleFilters = (open) => {
  const filters = document.querySelector("#filters")
  const html = document.documentElement

  if (coworkations.map) {
    coworkations.map.recenter = true
  }

  window.showFilters = open ?? !filters.classList.contains("active")

  if (window.showFilters) {
    filters.classList.add("active")
    html.classList.add("filters")
    html.classList.add("md:pl-[30rem]")
    filters.tabIndex = 0
    filters.focus()
  } else {
    filters.classList.remove("active")
    html.classList.remove("filters")
    html.classList.remove("md:pl-[30rem]")
    filters.tabIndex = -1
  }
}

window.closeFilters = closeFilters
window.toggleFilters = toggleFilters
window.showFilters = false
