import Swal from "sweetalert2"

const Toast = Swal.mixin({
  position: "bottom-right",
  showConfirmButton: false,
  timer: 1500,
  timerProgressBar: true,
  toast: true,
  customClass: {
    title: "!m-0",
    icon: "!border-0 !m-0",
    popup: "!flex gap-3 items-center !px-4 !pt-2 !pb-3",
  },
})

window.Swal = Swal.mixin({
  confirmButtonColor: "#3085d6",
  customClass: {
    cancelButton: "!font-semibold !text-lg !leading-tight !rounded-3xl",
    closeButton: "!rounded-2xl",
    confirmButton: "!font-semibold !text-lg !leading-tight !rounded-3xl",
    denyButton: "!font-semibold !text-lg !leading-tight !rounded-3xl",
    htmlContainer: "mx-3 mb-0 p-0",
    inputLabel: "sr-only",
    popup: "!rounded-2xl",
  },
  heightAuto: false,
})

window.toast = {
  info: (title, options) => Toast.fire({
    title,
    ...options,
  }),
  error: (title, options) => Toast.fire({
    title,
    iconHtml: "<i class=\"text-xl fa fa-times text-red-600\"/>",
    ...options,
  }),
  success: (title, options) => Toast.fire({
    title,
    iconHtml: "<i class=\"text-xl fa fa-check text-green-600\"/>",
    ...options,
  }),
}
