<template>
  <div class="custom-file">
    <input
      :id="id"
      class="custom-file-input"
      type="file"
      :accept="accept"
      :required="required"
      @change="loadTextFromFile"
    >
    <label class="custom-file-label truncate" :for="id" :class="{ 'text-[#c7cdd1]': !name.length }">
      {{ name || 'Choose file' }}
    </label>
  </div>
</template>

<script setup>
import { ref } from "vue"

defineProps({
  id: {
    type: String,
    default: "customFile",
  },
  accept: String,
  required: Boolean,
})

const emit = defineEmits(["input"])

const name = ref("")

const loadTextFromFile = (ev) => {
  const file = ev.target.files[0]
  const reader = new FileReader()

  name.value = file.name

  reader.onload = (e) => {
    emit("input", {
      name: file.name,
      content: e.target.result,
      size: file.size,
      type: file.type,
    })
  }

  reader.readAsDataURL(file)
}
</script>
