require("./bootstrap")
require("./dates")
require("./filters")
require("./notifications")
require("./search")
require("./templates")

import { createApp } from "vue"
import { findComponents } from "@/support"

const components = Object.entries(findComponents(require.context("@", true, /\.vue$/)))

window.createApp = () => {
  const app = createApp()

  for (const [name, component] of components) {
    app.component(name, component)
  }

  app.config.compilerOptions.whitespace = "preserve"
  app.config.globalProperties.document = document
  app.config.globalProperties.window = window

  return app
}

window.createApp().mount("#app")
