<template>
  <form method="post" @submit.prevent="savePromo">
    <div class="row">
      <div class="col-md-6 col-lg-7 order-1 order-md-0">
        <div class="form-group">
          <label for="image-element">Ad image</label>
          <file id="image-element" accept=".gif,.jpg,.png" @input="loadFile"/>
        </div>

        <div class="form-group">
          <label for="text-element">Ad text</label>
          <input
            id="text-element"
            v-model="text"
            name="text"
            class="form-control"
            :placeholder="textPlaceholder"
            required
          >
        </div>

        <div class="form-group">
          <label for="button-element">Ad call to action</label>
          <input
            id="button-element"
            v-model="button"
            name="button"
            class="form-control"
            :placeholder="buttonPlaceholder"
            required
          >
        </div>

        <div class="form-group">
          <label for="url-element">Ad link</label>
          <input
            id="url-element"
            v-model="url"
            name="url"
            class="form-control"
            :placeholder="urlPlaceholder"
            type="url"
            required
          >
        </div>
      </div>

      <div class="col-md-6 col-lg-5 order-0 order-md-1">
        <div class="mx-auto my-2">
          <img :src="imageUrl" class="block bg-light border rounded mx-auto mb-2">
          <div class="font-weight-bold">
            {{ textPreview }}
          </div>
          <div class="text-muted">
            {{ buttonPreview }}
          </div>
        </div>
      </div>
    </div>

    <button class="btn btn-primary mr-2" type="submit">
      Save changes
    </button>

    <a href="#promote" class="btn btn-secondary" data-toggle="modal" role="button">
      Place another promo
    </a>
  </form>
</template>

<script setup>
import File from "@/components/File.vue"
import { computed, ref } from "vue"

const props = defineProps({
  value: Object,
})

const id = ref(props.value.id)
const button = ref(props.value.button)
const buttonPlaceholder = "Your call to action"
const image = ref(undefined)
const imageUrl = ref(props.value.image)
const submitting = ref(false)
const text = ref(props.value.text)
const textPlaceholder = "Your promo text"
const url = ref(props.value.url)
const urlPlaceholder = "https://"

const buttonPreview = computed(() => button.value || buttonPlaceholder)
const textPreview = computed(() => text.value || textPlaceholder)

const loadFile = ({ content }) => {
  image.value = content
  imageUrl.value = content
}

const savePromo = () => {
  if (submitting.value) {
    return
  }

  spinner.show()

  submitting.value = true

  axios.patch(`/api/promos/${id.value}`, {
    button: button.value,
    image: image.value,
    text: text.value,
    url: url.value,
  }).then(({ data }) => {
    spinner.hide()

    imageUrl.value = data.image

    Swal.fire({
      title: "Promo updated",
      text: "Your changes have been saved",
      icon: "success",
    })
  }).catch(() => {
    spinner.hide()

    Swal.fire({
      title: "There was a problem updating your promo",
      text: "Your changes have not been saved",
      icon: "error",
    })
  }).finally(() => {
    submitting.value = false
  })
}
</script>

<style lang="scss" scoped>
  .image-preview {
    position: relative;
    width: 160px;
    height: 160px;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  img {
    width: 200px;
    height: 135px;
  }
</style>
