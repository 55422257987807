<template>
  <div class="alert alert-info py-3">
    <h3 class="h5">
      Invite your alumni to review {{ community.name }} on Coworkations 🤘
    </h3>

    <p>
      Reviews are shown on your community and trip pages, and your rating is shown in search
    </p>

    <div class="flex flex-col md:flex-row items-center justify-between gap-2">
      <input class="form-control" :value="url">

      <div class="flex gap-2 w-full md:w-auto whitespace-nowrap">
        <button
          class="btn btn-outline-info w-full"
          type="button"
          @click="copyLink"
        >
          Copy link
        </button>
        <button
          class="btn btn-info w-full"
          type="button"
          @click="sendInvites"
        >
          Send invites
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import useClipboard from "vue-clipboard3"
import { filter, uniq } from "lodash-es"

const props = defineProps({
  community: Object,
  url: String,
})

const { toClipboard } = useClipboard()

const copyLink = () => {
  toClipboard(props.url).then(() => {
    toast.success("Review link copied to clipboard")
  }, () => {
    toast.error("There was a problem copying the review link to the clipboard")
  })
}

const sendInvites = () => {
  Swal.fire({
    title: `<span>Send invites to review <span class="whitespace-nowrap">${props.community.name}</span></span>`,
    html: "Enter emails to send a personal invite to, one per line 🙏",
    input: "textarea",
    confirmButtonColor: "#17a2b8",
    confirmButtonText: "Send invites",
    preConfirm: (value) => {
      if (!value) {
        Swal.showValidationMessage("The emails field is required")

        return
      }

      const emails = uniq(filter(value.split("\n").map(email => email.trim())))

      return axios.post(`/api/communities/${props.community.slug}/reviews`, { emails })
        .then(result => result.data.emails)
        .catch(error => {
          if (error.response.data.message) {
            Swal.showValidationMessage(error.response.data.message)
          } else {
            Swal.showValidationMessage(error.response.statusText)
          }
        })
    },
    showCancelButton: true,
    showCloseButton: true,
  }).then(({ value }) => {
    if (!value) {
      return
    }

    Swal.fire({
      title: value.length === 1
        ? `Invite sent to ${value[0]} 🤘`
        : `Invites sent to ${value.length} emails 🤘`,
      text: "Ask them to check their email",
      icon: "success",
    })
  })
}
</script>
