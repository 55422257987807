const colors = require("tailwindcss/colors")
const defaultTheme = require("tailwindcss/defaultTheme")

module.exports = {
  content: [
    "./coworkations/templates/**/*.html",
    "./resources/**/*.js",
    "./resources/**/*.vue",
    "./templates/**/*.html.j2",
    "./templates/**/*.svg",
  ],
  corePlugins: {
    containers: false,
    visibility: false,
  },
  plugins: [
    require("@headlessui/tailwindcss"),
    require("@tailwindcss/typography"),
  ],
  safelist: [
    { pattern: /alert/ },
    { pattern: /amenity/ },
    { pattern: /badge/ },
    { pattern: /community/ },
    { pattern: /datepicker/ },
    { pattern: /easy-autocomplete/ },
    { pattern: /editor|cm|codemirror/i },
    { pattern: /fa/ },
    { pattern: /fixed|future|past|present/ },
    { pattern: /form|invalid/ },
    { pattern: /gm/ },
    { pattern: /marker/ },
    { pattern: /modal/ },
    { pattern: /noui/i },
    { pattern: /score/ },
    { pattern: /swal2/ },
    { pattern: /trip/ },
  ],
  theme: {
    extend: {
      colors: {
        green: { DEFAULT: colors.green["600"] },
        red: { DEFAULT: colors.red["600"] },
        sky: { DEFAULT: "#0092da" },
        slate: { DEFAULT: colors.slate["600"] },
        yellow: { DEFAULT: "#fbbe00" },
      },
      typography: (theme) => ({
        DEFAULT: {
          css: {
            "color": theme("colors.black"),
            "ul > li::marker": {
              color: null,
            },
          },
        },
      }),
    },
    fontFamily: {
      sans: ["'Nunito Sans'", defaultTheme.fontFamily.sans],
      serif: ["'Nunito Sans'", defaultTheme.fontFamily.serif],
    },
  },
}
